import logo from './logo.svg';
import './App.css';
import EventPage from './page/CouponPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainNotice from './page/MainNotice';
import CreateForm from './page/CreateForm';
import Chart from './page/Chart';
import DrawnPage from './page/DrawnPage';
import Transac from './page/transac';
import TestChart from './page/TestChart';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainNotice />} />
        <Route path='/notice/:id' element={<MainNotice />} />
        <Route path='/notice/7ba7de17-475e-4c20-96ec-1906cb5c7b5b' element={<EventPage />} />
        <Route path='/notice/a0e41438-6044-421c-abe2-818945d44b2c' element={<DrawnPage />} />
        <Route path='/transac' element={<Transac />} />
        <Route path='/create_form' element={<CreateForm />} />
        <Route path='/chart/:point_index/:token_index' element={<Chart />} />
        {/* <Route path='/test' element={<TestChart />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
